import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
	<Layout>
		<h1>Nichts gefunden</h1>
		<p>Das ist alles nicht geplant, einfach nur, alles spontan.</p>
	</Layout>
)

export default NotFoundPage
